@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&family=Roboto:wght@400;500&family=Montserrat:wght@300;400;500;600&display=swap');

body {
  /* background-color: #f1f2f6; */
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Inter', 'Roboto';
  background-color: #fefefe;
}

.prettyTitle {
  font-family: 'Montserrat';
  font-weight: 500;
}

.tile {
  display: block;
  height: 2rem;
  width: 2rem;
  min-height: 2rem;
  min-width: 2rem;
  font-size: 1.66rem;
  user-select: none;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  line-height: 2rem;
  margin: 0.05rem;
  background-color: #f2e8ce;
  border-radius: 0.15rem;
  font-weight: 500;
  border-bottom: #d4d0a8 solid 0.1rem;
  border-right: #d4d0a8 solid 0.1rem;
  border-top: none; 
  border-left: none; 
  color: #373737;
  
  transform: rotateY(0deg);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tile-back {
  color: transparent;
  transform: rotateY(180deg);
  border-bottom: #d4d0a8 solid 0.1rem;
  border-left: #d4d0a8 solid 0.1rem;
  border-right: none; 
  border-top: none; 
}

/*Start becoming face up*/
.tile-transition-enter { 
  color: transparent;
  transform: rotateY(180deg);
}

.tile-transition-enter-active {
  transform: rotateY(0deg);
  transition: transform 600ms;
}

.tile-transition-exit {
  transform: rotateY(0deg);
}

.tile-transition-exit-active {
  transform: rotateY(180deg);
  transition: transform 600ms;
}

.tile-node-exit {  
  transform: scale(1);
}

.tile-node-exit-active {
  transform: scale(0.1);
  transition: transform 800ms;
}

/*WORD NODES*/
.word-node-enter {
  transform: translateX(300px);
}

.word-node-enter-active {
  transform: translateX(0px);
  transition: transform 400ms;
}

.word-node-exit {
  transform: translateX(0px);
}

.word-node-exit-active {
  transform: translateX(300px);
  transition: transform 400ms;
}

/*CHAT NODES*/
.chat-node-enter {
  transform: scale(0.1);
}

.chat-node-enter-active {
  transform: scale(1);
  transition: transform 400ms;
}

